<template>
    <div class="bg-gray-100 p-4 mb-8 border">
        <div class="flex flex-row items-center justify-between">
            <h1 class="font-bold">Konto för {{ formatDate(period.startDate) }} är uppdelat i:</h1>
            <el-button @click="isResetModalVisible = true" type="default" plain class="p-1">
                <i class="fa-solid fa-arrows-rotate cursor-pointer" title="Återställ konto" />
            </el-button>
            <el-dialog :visible.sync="isResetModalVisible" width="20%" :modal="false" title="Återställ konto">
                <div class="flex flex-col pb-8">
                    <h1 class="font-bold">Är du säker att du vill återställa konto {{ selectedAccount.account }}?</h1>
                    <div class="flex flex-row justify-end">
                        <el-button @click="resetAccount(selectedAccount.account)" type="success">Återställ</el-button>
                        <el-button @click="isResetModalVisible = false" type="warning">Avbryt</el-button>
                    </div>
                </div>
            </el-dialog>
        </div>
        <div class="flex flex-row items-center space-x-2" v-for="(account, index) in dividedAccountsArray" :key="index">
            <p>{{ account.isInRow }}</p>
            <p class="font-bold">{{ isPeriod ? account.period : account.previousPeriod | swedishNumberFormat }} SEK</p>
            <p v-if="account.isInRow === account.originalRowName" class="font-bold">(Original)</p>
        </div>
    </div>
</template>

<script>
import moment from "moment";
import Api from "../../annualReport.api.js";

export default {
    props: {
        annualReportId: {
            type: String,
        },
        period: {
            type: Object,
        },
        dividedAccountsArray: {
            type: Array,
        },
        selectedAccount: {
            type: Object,
        },
        isPeriod: {
            type: Boolean,
        },
        titlesData: {
            type: Array,
        },
        selectedOptionSru: {
            type: String,
        },
        findAccountData: {
            type: Object,
        },
        financialPage: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            isResetModalVisible: false,
        };
    },
    methods: {
        formatDate(date) {
            if (!date) return;
            return moment(date).format("YYYY");
        },
        async resetAccount(account) {
            this.$emit("close");
            const resetData = {
                ...this.findAccountData,
                moveWholeAmountPeriod: this.isPeriod ? true : false,
                moveWholeAmountPreviousPeriod: this.isPeriod ? false : true,
                movedAmountPeriod: this.selectedAccount.period,
                movedAmountPreviousPeriod: this.selectedAccount.previousPeriod,
                originalRowName: this.dividedAccountsArray[0].originalRowName,
                previousSruCode: this.findAccountData.sruCode,
                movedToRowName: this.titlesData.find(option => option.sruCode === this.selectedOptionSru).name,
                resetAccount: this.findAccountData.account,
                resetSruCode: this.originalSruCode,
            };
            if (this.financialPage == "incomeStatement") {
                await Api.updateIncomeStatementDetails(this.annualReportId, resetData);
                this.$emit("update-income-statement-details");
            } else if (this.financialPage == "balanceSheet") {
                await Api.updateBalanceSheetDetails(this.annualReportId, resetData);
                this.$emit("update-balance-sheet-details");
            }
            this.$notify.success({ title: "Konto " + account + " är återställd", duration: 15000 });
            this.$emit("close-accounts-section-when-moved", false);
            this.$emit("account-reset");
        },
    },
    computed: {
        originalSruCode() {
            return this.dividedAccountsArray.find(account => account.isInRow === account.originalRowName).sruCode;
        },
    },
};
</script>
